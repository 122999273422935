<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import FontSpan from '../typography/FontSpan.svelte';
	const dispatch = createEventDispatcher<{ checked: boolean }>();

	export let label: string = '';
	export let checked: boolean;
	let className = '';
	export { className as class };

	function toggle(e: any) {
		checked = !checked;
		dispatch('checked', checked as boolean);
	}
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
	class="flex justify-start items-start {className}"
	on:keypress|stopPropagation
	on:click|stopPropagation
>
	<div
		role="checkbox"
		tabindex="0"
		aria-checked={checked ? 'true': 'false'}
		on:keypress
		on:click={toggle}
		class="rounded w-5 h-5 flex flex-shrink-0
        justify-center items-center cursor-pointer"
	>

		{#if checked}
			<svg
				class="text-gray-70"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="24/ outlined / suggested / square / check">
					<path
						id="Vector"
						d="M9 11L12 14L22 4"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						id="Vector_2"
						d="M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
			</svg>
		{:else}
			<svg
				class="text-gray-70"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="checkbox">
					<rect
						id="Unselected"
						x="4"
						y="4"
						width="16"
						height="16"
						rx="2"
						stroke="currentColor"
						stroke-width="2"
					/>
				</g>
			</svg>
		{/if}
	</div>
	{#if label.length > 0}
		<FontSpan varient="body-R-04" class="text-white ml-2">{label}</FontSpan>
	{/if}
</div>
