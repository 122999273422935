<script lang="ts">
	import { goto } from '$app/navigation';
	import LikeButton from '$lib/components/button/LikeButton.svelte';
	import Checkbox from '$lib/components/checkbox/Checkbox.svelte';
	import { LikeService } from '$lib/service/LikeService';
	import { authenticatedUsingEmail, keycloak } from '$lib/stores/userstore';
	import type { TokenLikes } from '$lib/types';
	import { createEventDispatcher } from 'svelte';
	import LazyImage from './LazyImage.svelte';
	import MetamaskIcon from '../icons/MetamaskIcon.svelte';
	import { notifications } from '../toast/notifications';
	import FontSpan from '../typography/FontSpan.svelte';

	export let contract: string;
	export let tokenID: string;
	export let orderID: string;
	export let network: string;
	export let showSelection: boolean = false;
	export let selected: boolean = false;
	export let userLike: TokenLikes | undefined = undefined;
	export let orderType: string = '';
	export let status: string = '';
	export let walletType: string = 'metamask';

	let likeservice: LikeService | undefined = undefined;

	$: {
		likeservice = new LikeService($keycloak);
	}

	let likeUpdateInProgress = false;
	async function likeHandler() {
		likeUpdateInProgress = true;
		if (userLike && likeservice && $authenticatedUsingEmail) {
			try {
				await likeservice.LikeOrder(orderID);
				userLike.liked = true;
				userLike.count += 1;
			} catch (error) {
				notifications.danger(`Failed to like Tank NFT #${orderID}.`, 5000);
			} finally {
				likeUpdateInProgress = false;
			}
		}
	}

	async function unLikeHandler() {
		likeUpdateInProgress = true;
		if (userLike && likeservice && $authenticatedUsingEmail) {
			try {
				await likeservice.UnlikeOrder(userLike);
				userLike.liked = false;
				userLike.count -= 1;
				// await refreshLikes();
			} catch (error) {
				notifications.danger(`Failed to unlike Tank NFT #${orderID}.`, 5000);
			} finally {
				likeUpdateInProgress = false;
			}
		}
	}

	function openTankDetails() {
		if (orderID != '0') {
			goto(`/tank/${orderID}`);
		} else {
			goto(`/tanknft/${network}/${contract}/${tokenID}`);
		}
	}

	const dispatch = createEventDispatcher<{ checked: boolean }>();

	const dispatchHighlight = createEventDispatcher<{ highlight: boolean }>();

	function handleMouseOver() {
		dispatchHighlight('highlight', true);
	}
	function handleMouseOut() {
		dispatchHighlight('highlight', false);
	}

	function toStatusCSS(status: string) {
		switch (status) {
			case 'Cancelled':
				return 'bg-gray-80';
			case 'Sold':
				return 'bg-error-500';
			case 'Purchased':
				return 'bg-blue-600';
			default:
				return 'bg-white';
		}
	}

	$: statusCSS = toStatusCSS(status);
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div
	role="listitem"
	tabindex="0"
	data-testid={`card-token-${tokenID}`}
	class="relative w-[226px] pt-0 card-token bg-bg border-gray-350 rounded-xl border-solid border pb-5"
	on:mouseover={handleMouseOver}
	on:mouseleave={handleMouseOut}
	on:focus={() => {}}
	on:blur={() => {}}
	on:click={openTankDetails}
	on:keypress
>
	{#if orderType.length > 0}
		<div class="orderTypeContainer p-1 flex absolute top-3 left-3 z-10 w-fit">
			<FontSpan varient="body-B-09" class="text-white">{orderType}</FontSpan>
		</div>
	{/if}
	{#if userLike}
		<div class="flex flex-row absolute top-3 right-3 z-20">
			<LikeButton
				likes={userLike.count}
				like={userLike.liked}
				on:liked={(e) => {
					if (!likeUpdateInProgress) {
						if (e.detail) {
							likeHandler();
						} else {
							unLikeHandler();
						}
					}
				}}
			/>
		</div>
	{/if}
	<div class="w-fit mx-auto">
		<LazyImage {tokenID} />
	</div>
	<div class="px-3 overflow-x-hidden">
		{#if status.length > 0}
			<FontSpan
				varient="body-B-09"
				class="mt-3 px-[5px] py-1 text-white status {statusCSS} w-fit mb-3">{status}</FontSpan
			>
		{/if}

		<div class="flex items-center">
			<FontSpan varient="body-B-07" class="text-white">Fortress-Arena NFT #{tokenID}</FontSpan>
		</div>

		<div class="flex flex-col mt-1">
			<div class="flex flex-col justify-between">
				<div class="money flex grow">
					<slot name="price">
						<div class="icon-tank-token20 w-[22px] h-[22px] mr-2" />
						<h2 class="dark:text-white text-center text-sm flex self-center">N/A</h2>
					</slot>
				</div>
			</div>
			<div class="flex flex-row justify-between">
				{#if showSelection}
					<Checkbox
						checked={selected}
						on:checked={(evt) => {
							dispatch('checked', evt.detail);
						}}
					/>
					{#if walletType === 'metamask'}
						<MetamaskIcon />
					{/if}
				{/if}
			</div>
		</div>
	</div>
</div>

<style>
	.card-token:hover {
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		border-image: linear-gradient(
				90deg,
				var(--secondary-600-color) 0%,
				var(--secondary-400-color) 100%
			)
			1;
	}

	.orderTypeContainer {
		background: rgba(67, 148, 215, 0.7);
		box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(4px);
	}
</style>
