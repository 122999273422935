<script lang="ts">
	import { IMAGE_CACHE_SERVICE } from '../../../constants';
	export let tokenID: string;

	let className = '';
	export { className as class };

	let props: any = { 'data-placeholder': '' };
	function handleLoad() {
		props = {};
	}
</script>

<div class="rounded-lg w-[223px] relative overflow-hidden {className}" {...props}>
	<img
		on:load={handleLoad}
		width="223"
		height="278.75"
		class="object-cover"
		src={`${IMAGE_CACHE_SERVICE}/${tokenID}.webp`}
		alt="Tank NFT"
		srcset=""
		loading="lazy"
	/>
</div>
